import * as React from "react";
import Axios from "axios";
export const otp = "https://theflowerluxecebu.com/api";
export const url = "https://www.smestoreph.com"; ////"http://192.168.1.148:9091"; //   process.env.REACT_APP_STORE_STORE_ENDPOINT //
export const urlFirebase = process.env.REACT_APP_STORE_STATISTIC; // "https://www.smestoreph.com"  //
export const urlSupBase = "https://www.smestoreph.com"; // "http://192.168.1.148:3001" //
export const axios = Axios.create({
  baseURL: url,
  timeOut: 3000,
  headers: {
    "Content-Type": "application/json",
  },
});
export const axiosOTP = Axios.create({
  baseURL: otp,
  timeOut: 3000,
  headers: {
    "Content-Type": "application/json",
  },
});
export const axiosUpload = Axios.create({
  baseURL: url,
  timeOut: 3000,
  headers: {
    // "Content-Type": "application/json",
    "Content-Type": "image/jpeg",
  },
});
export const productStats = Axios.create({
  baseURL: urlFirebase,
  timeOut: 3000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const productLike = Axios.create({
  baseURL: urlSupBase,
  timeOut: 3000,
  headers: {
    "Content-Type": "application/json",
  },
});
